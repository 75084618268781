<template>
  <section class="services">
    <router-link
      v-for="service in services"
      :key="service.slug"
      class="services__item"
      :to="`/art-advisory/${service.slug}`"
    >
      <img class="services__item-image" :src="service.preview_image" alt="art" />
      <h2 class="services__item-title text-title">{{ service.title }}</h2>
      <p class="services__item-description text-description">
        {{ service.preview_title }}
      </p>
    </router-link>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  head: {
    title: function () {
      return {
        inner: "Art Advisory browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Art Advisory browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Art Advisory browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Art Advisory browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Art Advisory browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Art Advisory browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Art Advisory browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Art Advisory browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Art Advisory browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Art Advisory",
        to: "/art-advisory",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    await this.apiArtAdvisories();
  },
  computed: {
    ...mapGetters("publicapi/services", {
      services: "getArtAdvisories",
    }),
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicapi/services", ["apiArtAdvisories"]),
  },
};
</script>

<style lang="scss" scoped>
.services {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2.8rem;
  grid-row-gap: 6.5rem;
  padding: 21rem 10.5rem 11rem;
  @media screen and (max-width: $md) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $xs) {
    grid-template-columns: 1fr;
    grid-row-gap: 7.5rem;
    padding: 21rem 2rem 11rem;
  }
  &__item {
    display: block;
    cursor: pointer;
    text-decoration: none;
    @media screen and (max-width: $xxs) {
      width: 100%;
    }
    &-image {
      width: 100%;
      border-radius: 4px;
      @media screen and (max-width: $xxs) {
        width: 100%;
        height: 100vw;
      }
    }
    &-title {
      font-size: 4rem;
      line-height: 1.2;
      margin: 15px 0 2rem;
    }
    &-description {
    }
  }
}
</style>

